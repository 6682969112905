import { useMemo, useState } from "react";
import styles from "./app.module.scss";

function App() {
    const translationState = (window as any).app.preloadState.translation;
    const dropdownOptions: { label: string, value: string }[] = [{ label: translationState["sortingDropdown.sortingAlphabetically"], value: "" },
        { label: translationState["sortingDropdown.sortingPriceAscending"], value: "price" },
        { label: translationState["sortingDropdown.sortingPriceDescending"], value: "price-descending" }] 

    const urlParams = new URLSearchParams(window.location.search);
    const sortBy = urlParams.get('sortBy');

    const onSelected = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        if (!ev.target.value) {
            urlParams.delete('sortBy');
        } else {
            urlParams.set('sortBy', ev.target.value);
        }
        window.location.search = urlParams.toString();
    }

  return <div className={styles.wrapper}>
      <p>{translationState["sortingDropdown.sortingLabel"]}</p>
      <select value={sortBy || ""} onChange={onSelected}>
          {dropdownOptions.map(dropdownOption =>
              <option value={dropdownOption.value} key={dropdownOption.value}>
                  {dropdownOption.label}
              </option>)}
      </select>
    </div>
}

export default App;
